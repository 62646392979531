<template>
  <div class="panel panel-default">
    <div class="panel-heading">四川植烟土壤管理 > 新增土壤数据</div>
    <div class="panel-body">
      <div class="table-responsive input-form">
        <p><b>基本信息</b></p>
        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
              <tr class="th">
                <th>市州</th>
                <th>区县</th>
                <th>乡镇</th>
                <th>村组</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <select v-model="city_state">
                    <option
                      v-for="item in sichuanLocationArr"
                      :key="item"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                </td>
                <td><input type="text" v-model="district_county" /></td>
                <td><input type="text" v-model="township" /></td>
                <td>
                  <input type="text" v-model="village" style="width: 200px" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <br />

      <div class="table-responsive input-form">
        <p><b>烟草采样点层次详细信息</b></p>
        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
              <tr class="th">
                <th>样品编号</th>
                <th>土层厚度</th>
                <th>pH</th>
                <th>有机质</th>
                <th>全氮</th>
                <th>全磷</th>
                <th>全钾</th>
                <th>碱解氮</th>
                <th>有效磷</th>
                <th>速效钾</th>
                <th>缓效钾</th>
                <th>CEC</th>
                <th>水溶性盐总量</th>
                <th>交换性钙</th>
                <th>交换性镁</th>
                <th>有效铜</th>
                <th>有效锌</th>
                <th>有效铁</th>
                <th>有效锰</th>
                <th>有效硼</th>
                <th>有效钼</th>
                <th>有效硫</th>
                <th>氯离子</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in SichuanTobaccoDetailedMessageList"
                :key="index"
              >
                <td><input type="text" v-model="item.sample_id" /></td>
                <td><input type="text" v-model="item.soil_thickness" /></td>
                <td><input type="text" v-model="item.pH" /></td>
                <td><input type="text" v-model="item.organic_matter" /></td>
                <td><input type="text" v-model="item.total_nitrogen" /></td>
                <td><input type="text" v-model="item.total_phosphorus" /></td>
                <td><input type="text" v-model="item.total_kalium" /></td>
                <td>
                  <input
                    type="text"
                    v-model="item.alkali_hydrolyzable_nitrogen"
                  />
                </td>
                <td><input type="text" v-model="item.available_phosphor" /></td>
                <td>
                  <input type="text" v-model="item.rapidly_available_kalium" />
                </td>
                <td>
                  <input type="text" v-model="item.slowly_available_kalium" />
                </td>
                <td><input type="text" v-model="item.cec" /></td>
                <td><input type="text" v-model="item.water_soluble_salt" /></td>
                <td>
                  <input type="text" v-model="item.exchangeable_calcium" />
                </td>
                <td>
                  <input type="text" v-model="item.exchangeable_magnesium" />
                </td>
                <td><input type="text" v-model="item.available_copper" /></td>
                <td><input type="text" v-model="item.available_zinc" /></td>
                <td><input type="text" v-model="item.available_iron" /></td>
                <td>
                  <input type="text" v-model="item.available_manganese" />
                </td>
                <td><input type="text" v-model="item.available_boron" /></td>
                <td>
                  <input type="text" v-model="item.available_molybdenum" />
                </td>
                <td><input type="text" v-model="item.available_sulphur" /></td>
                <td><input type="text" v-model="item.chloridion" /></td>
              </tr>
            </tbody>
          </table>
        </div>
        <input
          type="submit"
          class="btn btn-default my-submit"
          value="增加层次"
          @click="addRow()"
        /><span v-html="'\u00a0\u00a0\u00a0'"></span>
        <input
          type="submit"
          class="btn btn-default my-submit"
          value="减少层次"
          @click="removeRow()"
        />
      </div>

      <br />
      <input
        type="submit"
        class="btn btn-default my-submit"
        value="提交"
        @click="handleSubmit()"
      />
      <!-- 表单校验 -->
      <span v-if="errors.length">
        <b> 请检查下列问题: </b
        ><span v-for="error in errors" :key="error">{{ error }} </span>
      </span>
    </div>
  </div>
</template>

<script>
import globalData from "@/global.js";
import axios from "axios";
import qs from "qs";

export default {
  data() {
    return {
      sichuanLocationArr: globalData.sichuanLocationArr,
      // 表单校验
      errors: [],
      // 基本信息
      id: "",
      city_state: "",
      district_county: "",
      township: "",
      village: "",
      // 层次信息
      SichuanTobaccoDetailedMessageList: [],
    };
  },
  methods: {
    handleSubmit() {
      const {
        city_state,
        district_county,
        township,
        village,
        SichuanTobaccoDetailedMessageList,
      } = this;

      let baseData = {
        city_state,
        district_county,
        township,
        village,
      };

      console.log(baseData);

      // 表单校验
      this.errors = [];

      if (!this.city_state) {
        this.errors.push("必填市州;");
      }
      if (this.city_state) {
        let promiseArr = [];

        // 基本信息
        const sichuanTobaccoDetailedMessagePromise = new Promise(
          (resolve, reject) => {
            axios
              .post(
                `./data_tobacco/addSichuanTobaccoBasicData`,
                qs.stringify(baseData)
              )
              .then((res) => {
                if (res.data.code != 200) {
                  reject();
                }
                this.id = res.data.data.id;
                // 更新数据
                this.$store.dispatch("getSichuanListAction");
                resolve();
              });
          }
        );

        sichuanTobaccoDetailedMessagePromise
          .then(() => {
            if (SichuanTobaccoDetailedMessageList.length == 0) return;
            for (let i = 0; i < SichuanTobaccoDetailedMessageList.length; i++) {
              const element = SichuanTobaccoDetailedMessageList[i];
              element.id = this.id;
              promiseArr.push(
                new Promise((resolve, reject) => {
                  axios
                    .post(
                      `./data_tobacco/addSichuanTobaccoDetailedData`,
                      qs.stringify(element)
                    )
                    .then((res) => {
                      // console.log(res)
                      if (res.data.code != 200) reject();
                      resolve();
                    });
                })
              );
            }
            return Promise.all(promiseArr);
          })
          .then(() => {
            // 页面跳转
            this.$message({
              message: "新增土壤数据成功",
              type: "success",
            });
            this.$router.push(`/sichuanlist`);
          })
          .catch(() => {
            this.$message.error("操作失败, 请检查服务器");
          });
      }
    },
    addRow() {
      this.SichuanTobaccoDetailedMessageList.push({
        sample_id: "",
        soil_thickness: "",
        pH: "",
        organic_matter: "",
        total_nitrogen: "",
        total_phosphorus: "",
        total_kalium: "",
        alkali_hydrolyzable_nitrogen: "",
        available_phosphor: "",
        rapidly_available_kalium: "",
        slowly_available_kalium: "",
        cec: "",
        water_soluble_salt: "",
        exchangeable_calcium: "",
        exchangeable_magnesium: "",
        available_copper: "",
        available_zinc: "",
        available_iron: "",
        available_manganese: "",
        available_boron: "",
        available_molybdenum: "",
        available_sulphur: "",
        chloridion: "",
      });
    },
    removeRow() {
      const len = this.SichuanTobaccoDetailedMessageList.length;
      if (len == 0) return;
      this.SichuanTobaccoDetailedMessageList.splice(len - 1, 1);
    },
  },
};
</script>

<style scoped>
.table {
  margin-bottom: 0px;
}
table:nth-child(1) {
  width: 35%;
}
.my-submit {
  margin-top: 7px;
}

table select,
table input {
  width: 100px;
}
select {
  padding: 1px 2px;
  height: 24px;
}
</style>